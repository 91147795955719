<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { postAction, getAction } from '@/command/netTool'
import moment from 'moment'
export default {
  name: 'ruralVideo',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    this.getInit()
  },
  methods: {
    getInit() {
      api.command.getList.call(this, {
        url: '/farmCountryVideo/page',
        current: 1,
      })
    },
    getHeader() {
      return [
        {
          name: '发布时间',
          type: 'rangePicker',
          placeholder: '请选择发布日期',
          keys: ['createTimeGe', 'createTimeLe'],
        },
      ]
    },
    getColumns() {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '视频预览图',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                <img src={records.bannerUrl.split(',')[0]} />
              </div>
            )
          },
        },
         {
          dataIndex: 'name',
          title: '视频名称',
          align: 'left',
        },
        {
          dataIndex: 'sort',
          title: '排序',
          align: 'left',
        },
        {
          dataIndex: 'createTime',
          title: '发布时间',
          align: 'left',
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  this.onRowSelect(records)
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '是否确认删除?',
                onClick: () => {
                  return api.command.del.call(this, {
                    url: '/farmCountryVideo/deleteBatch',
                    params: { idList: [records.id] },
                  })
                },
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.onRowSelect()
          },
        },
      ]
    },
    getDrawerForm(source, formData) {
      const form = []
      return form
    },
    onRowSelect(records = {}) {
      if (records.id) {
        this.$router.push('/contentManagement/ruralVideoDetail?id=' + records.id)
      } else {
        this.$router.push('/contentManagement/ruralVideoDetail?')
      }
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
